<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template v-slot:body>
                  <b-overlay :show="loader">
                      <b-row>
                          <b-col xl="12" lg="12" sm="12">
                              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                  <b-form @submit.prevent="handleSubmit(rejectSubmit)" @reset.prevent="reset">
                                      <b-row>
                                          <!-- remarks -->
                                          <b-col lg="12" sm="12">
                                            <ValidationProvider name="Remarks" vid="remarks" :rules="{required:true}">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="3"
                                                      label-for="remarks"
                                                      slot-scope="{ valid, errors }">
                                                      <template v-slot:label>
                                                      {{ $t('globalTrans.remarks') }}
                                                      </template>
                                                      <b-textarea
                                                      id="remarks"
                                                      v-model.number="data.remarks"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-textarea>
                                                      <div class="invalid-feedback d-block">
                                                      {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                      </b-row>
                                      <div class="row">
                                          <div class="col-sm-3"></div>
                                          <div class="col text-right">
                                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                              &nbsp;
                                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('reject-modal')">{{ $t('globalTrans.cancel') }}</b-button>
                                          </div>
                                      </div>
                                  </b-form>
                              </ValidationObserver>
                          </b-col>
                      </b-row>
                  </b-overlay>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import { applicationRejectApi } from '../../api/routes'
  export default {
      name: 'FormLayout',
      props: ['id'],
      created () {
        if (this.id) {
          this.data.status = 2
        }
      },
      mounted () {
      },
      data () {
          return {
              saveBtnName: this.id ? this.$t('globalTrans.reject') : this.$t('globalTrans.reject'),
              data: {
                  remarks: ''
              },
              loader: false
          }
      },
      methods: {
          getInfo () {
              const tmpData = this.$store.state.list.find(item => item.id === this.id)
              return JSON.parse(JSON.stringify(tmpData))
          },
          async rejectSubmit () {
              this.loader = true
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              let result = null
              const loadinState = { loading: false, listReload: true }
              result = await RestApi.postData(internationalTradeFairServiceBaseUrl, `${applicationRejectApi}/${this.id}`, this.data)
              this.$store.dispatch('mutateCommonProperties', loadinState)
              if (result.success) {
                  this.$toast.success({
                      title: this.$t('globalTrans.success'),
                      message: result.message,
                      color: '#D6E09B'
                  })
                  this.$bvModal.hide('reject-modal')
              } else {
                  this.$refs.form.setErrors(result.errors)
              }
              this.loader = false
          }
      }

  }
  </script>
