<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('ditfDivisionAppManagement.divisionFairApplication') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form id="form" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col sm="4">
                  <ValidationProvider name="Applicant Name" vid="applicant_name" :rules="{required:false}" v-slot="{ errors }">
                    <b-form-group
                      label-for="applicant_name">
                      <template v-slot:label>
                        {{ $t('globalTrans.applicant_name') }}
                      </template>
                      <b-form-input
                        plain
                        id="applicant_name"
                        v-model="search.applicant_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Applicant Mobile" vid="applicant_mobile" :rules="{required:false}" v-slot="{ errors }">
                    <b-form-group
                      label-for="applicant_mobile">
                      <template v-slot:label>
                        {{ $t('globalTrans.mobile') }}
                      </template>
                      <b-form-input
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        plain
                        id="applicant_mobile"
                        v-model="search.applicant_mobile"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4" style="margin-top: 19px;">
                  <b-button type="submit" size="sm" variant="primary"><i class="ri-search-line"></i> {{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('ditfDivisionAppManagement.divisionFairApplication') }} {{ $t('globalTrans.pending') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(applicant_mobile)="data">
                    {{ EngBangNum(data.item.applicant_mobile.toString()) }}
                  </template>
                  <template v-slot:head(check)>
                    <b-form-checkbox
                      style="display: table-cell"
                      class="col-form-label-sm font-weight-bold mb-0"
                      name="checked"
                      v-model="selectAll"
                      @change="checkAll"
                      :value=true
                      :unchecked-value=false
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(check)="data">
                    <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group v-if="data.item.status == 1" v-model="selectedItem" style="margin-top: 5px" :aria-describedby="ariaDescribedby">
                        <b-form-checkbox :value="data.item.id" style="margin-right: 0"></b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </template>
                  <template #custom-foot="">
                    <b-tr>
                      <b-th colspan="10" class="text-right">
                        <button v-b-modal.modal-approve-return @click="confirm(), setData(selectedItem)" class="btn btn-primary btn-sm mr-2" :title="$t('ditfDivisionAppManagement.approve')" :disabled="selectedItem.length > 0 ? false : true">
                          <i class="fas fa-list"></i> {{ $t('ditfDivisionAppManagement.approve') }}
                        </button>
                      </b-th>
                    </b-tr>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- view -->
                    <b-button :title="$t('globalTrans.details')" v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                     <!-- reject modal -->
                  <b-button v-if="data.item.status == 1" class="moc-action-btn btn-danger" variant="danger" size="sm" @click="rejectModal(data.item)" v-b-modal.reject-modal :title="$t('globalTrans.reject')"><i class="ri-user-unfollow-fill"></i></b-button>
                  </template>
                  <template v-slot:cell(year)="data">
                    {{ $n(data.item.year, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status === 1">
                      {{$t('globalTrans.pending')}}
                    </span>
                    <span class="badge badge-info" v-if="data.item.status === 2">
                      {{ $t('globalTrans.approved') }}
                    </span>
                    <span class="badge badge-danger" v-if="data.item.status === 3">
                      {{ $t('ditfDivisionAppManagement.rejected') }}
                    </span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-4" size="xl" hide-footer ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('externalTradeFair.application') + ' ' + $t('globalTrans.details') }}
        <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <Details :id="detailsItemId" :circular_type="circularTypeId" :key="detailsItemId" ref="details"/>
    </b-modal>
    <!-- reject modal -->
    <b-modal id="reject-modal" size="md" :title="$t('globalTrans.reject')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
          <Reject :id="rejectId"/>
        </b-modal>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { receiveAppsList, applicationApproveApi } from '../../api/routes'
import Details from './Details/Details.vue'
import Reject from './Reject.vue'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Reject
  },
  data () {
    return {
      detailsItemId: '',
      valid: '',
      sortBy: '',
      itemData: [],
      selected_item: [],
      selectedItem: [],
      selectAll: false,
      rejectId: '',
      status: 0,
      search: {
        limit: 20
      },
      dhakaFairList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'globalTrans.applicant_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'globalTrans.mobile', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 5, thStyle: { width: '7%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 6, thStyle: { width: '5%' } }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'applicant_name' },
          { key: 'applicant_mobile' },
          { key: 'status' },
          { key: 'action' },
          { key: 'check' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'applicant_name' },
          { key: 'applicant_mobile' },
          { key: 'status' },
          { key: 'action' },
          { key: 'check' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    detailsData (item) {
      this.detailsItemId = item.id
      this.item = item
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    checkAll () {
        if (this.selectAll) {
          this.selectedItem.splice(0, this.selectedItem.length)
          this.listData.forEach(element => {
            if (element.status === 1) {
              this.selectedItem.push(element.id)
            }
          })
        } else {
          this.selectedItem.splice(0, this.selectedItem.length)
        }
    },
    setData (data) {
        this.selected_item = data
    },
    confirm () {
        const title = window.vm.$t('national_award.approve_final_msg')
        window.vm.$swal({
          title: title,
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
             this.approveSubmit()
          }
        })
    },
    async approveSubmit () {
        const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, applicationApproveApi, {
          ids: this.selectedItem.length > 0 ? this.selected_item : [this.selected_item]
        })
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: result.message,
            color: '#D6E09B'
          })
          this.loadData()
          this.selectedItem = []
          this.selectAll = false
        }
    },
    rejectModal (item) {
      this.rejectId = item.id
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, receiveAppsList, params)

      if (result.success) {
        this.selectedItem = []
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
